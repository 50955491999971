@import url("Colors.css");
/* .email-form-container {
  max-width: 700px;
  margin: auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

.email-form-header {
  text-align: center;
  margin-bottom: 20px;
  color:  #ffffffc4;;
}

.email-form-section {
  background: #80808054;
  padding: 10px;
  border-radius: 10px;
  margin: 5px;
}

h4 {
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
}
.email-form-section h4 {
  font-size: 16px;
  font-weight: bold;
}

.email-form-textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  color: black;
  background: #ffffffdb;
}

.email-form-textarea:focus {
  border: solid;
}

.email-form-textarea::placeholder {
  color: gray;
}

.email-form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 10px;
  background: #ffffffc4;
}

.email-form-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background: white;
  margin-bottom: 10px;
}

.email-form-button {
  width: 100%;
  padding: 12px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.email-form-button:hover {
  background: #332E16E5;
}

.email-form-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  background-color: gray;
}

/* Conteneur principal qui s'adapte dynamiquement */
.bart-email-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* gap: 20px;
  padding-left: 5%;
  padding-right: 5%; */
  background: url(/public/images/fond/sso_main_bg.svg);
  height: 100vh; /* Hauteur pleine pour gérer les scrolls indépendants */
}

/* .email-form-container {
  flex: 2;
  background: #BDB58CB5;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  display: flex;
  flex-direction: column;
  height: 100%;
} */

.email-form-content {
  flex: 1;
  overflow-y: auto; /* Scroll indépendant pour le contenu du formulaire */
  padding-bottom: 20px; /* Espace pour éviter que le contenu ne passe sous le bouton */
}

/* Mode centré quand il n'y a pas de résultats */
.email-form-center {
  flex-direction: column;
  align-items: center;
}

/* Mode split en 2/3 - 1/3 quand il y a des résultats */
.email-form-split {
  flex-direction: row;
}

/* Formulaire - 2/3 de l'écran si résultats affichés */
.email-form-container {
  display: flex;
  flex: 3;
  flex-direction: column;
  /* background: #BDB58CE0; */
  padding: 24px;
  border-radius: 8px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  width: 100%;
  
  height: 100%;
  /* max-width: 700px; */
}

/* Contenu du formulaire qui peut défiler */
.email-form-content {
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none;
  padding-bottom: 60px;
  /* Ajuste la hauteur max du formulaire */
  /* max-height: 400px;  */
  /* Espace pour le bouton */
}

/* Chrome, edge, opéra : cacher la barre */
.email-form-content::-webkit-scrollbar {
  display: none;
}

/* Bouton fixe en bas */
.email-form-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 12px 0;
  z-index: 10; /* Assure que le bouton reste au-dessus du contenu */
  text-align: center;
}

/* Bouton */
.email-form-button {
  background: #332E16E5;
  color: #fce77b;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  transition: background 0.3s;
}

.email-form-button:hover {
  background: #332E16E5;
}

/* Résultats - 1/3 de l'écran */
.email-result-container {
  flex: 1;
  /* background: #d0d0d0de; */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Scroll indépendant pour les résultats */
  max-height: 100vh; /* Limite à la hauteur de l'écran */
  border: 1px solid #ccc;
}

/* Titre des résultats */
.email-result-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffffb8;
  padding: 20px;
  text-align: center;
}

/* Liste des résultats */
.email-result-list {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #ffffffb8;
}

.email-result-item {
  background: rgba(255, 255, 255, 0.75);
  padding: 12px;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.email-result-question {
  font-weight: bold;
  font-size: small;
}

.email-result-answer,
.email-result-score {
  font-weight: bold;
  color: #9c8a2e;
  font-size: small;
}

/* Gestion responsive */
@media (max-width: 768px) {
  .bart-email-form-wrapper {
      flex-direction: column;
      height: auto; /* Sur mobile, on désactive la hauteur fixe */
  }
  
  .email-form-container, .email-result-container {
      width: 100%;
      height: auto; /* Ajustement pour mobile */
  }
  
  .email-result-container {
      max-height: 50vh; /* Limite la hauteur des résultats sur mobile */
  }
}

.feedback-container {
  margin-top: 15px;
}
.feedback-btn {
  border: none;
  border-radius: 15px;
  margin: 5px;
  background: transparent;
}

.text-eval {
  font-weight: bold;
  color: white;
  font-style: oblique;
}