.modal {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: #00000099;
}

.modal-main {
    position: fixed;
    background: white;
    width: 35rem;
    height: auto;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    padding-bottom: 20px;
}
.modal-head {
    padding: 10px;
    width: 100%;
    text-align: center;
    color: white;
}
.modal-body {
    display: flex;
    height: 240px;
    padding: 25px;
    width: 100%;
}
.modal.display-block {
    display: block;
}

.modal.display-none {
    display: none;
}
#modal-close-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#modal-form-btn {
    background: #1d572f;
    padding: 15px;
    font-size: medium;
    border: 1px solid transparent;
    border-radius: 15px;
    color: white;
}
#modal-form-btn:hover {
    cursor: pointer;
    background: white;
    padding: 15px;
    font-size: medium;
    border: 1px solid  #1d572f;
    border-radius: 15px;
    color: #1d572f;
}

.MyModalClass {
    background: 'red'
}