.slm-main-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 158vh;
  padding-left: 60px;
  padding-right: 60px;
  background-image: url(/public/images/fond/sso_main_bg.svg);
  color: white;
}
.slm-main-head {
  display: flex;
  height: 10vh;
  justify-content: center;
  align-items: center;
}
.slm-main-body {
  display: flex;
  flex: 7;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: none;
}

/* Chrome, edge, opéra : cacher la barre */
.slm-main-body::-webkit-scrollbar {
  display: none;
}

.slm-main-title-part-one {
  display: flex;
  height: 20vh
}
.slm-main-body-part-one {
  display: flex;
  height: 40vh
}
.slm-main-body-part-two {
  display: flex;
  flex: 1;
}

.slm-lr-title-part {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #808080a8;
  height: 19vh;
  border-radius: 5px;
  margin: 5px;
}
.slm-lr-body-part {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #808080a8;
  height: 48vh;
  border-radius: 5px;
  margin: 5px;
}
.slm-left-part {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* background-color: #007bff; */
}
.slm-right-part {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* background-color: #fce77b; */
}
.slm-right-part-one {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* background-color: #93d498; */
}
.slm-right-part-two {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* background-color: #ae96e4; */
}

.slm-box-container {
  display: flex;
  /* flex: 1; */
}

.slm-email-form-div {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* Conteneur principal qui s'adapte dynamiquement */
.slm-email-form-wrapper {
  display: flex;
  /* justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: auto; */
}

.slm-email-form-header {
  text-align: center;
  margin-bottom: 20px;
}

.slm-mail-container-section {
  display: flex;
  flex: 1;

}

.slm-mail-container-left {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px 15px 15px 15px;
}
.slm-mail-container-right {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px 15px 15px 15px;
}

.slm-email-form-section {
  /* background: gray; */
  display: flex;
  flex-direction: column;
  flex: 1;
}

.slm-email-form-section h3 {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.slm-h3 {
  display: flex;
  flex: 1;
  padding: 10px 0px 10px 10px;
  color: white;
}

.slm-email-form-textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  color: black;
}

.slm-email-form-textarea:focus {
  border: solid;
}

.slm-email-form-textarea::placeholder {
  color: gray;
}

.slm-email-form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 25px;
}

.slm-email-form-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background: white;
  margin-bottom: 10px;
}

.slm-email-form-button {
  width: 100%;
  padding: 12px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.slm-email-form-button:hover {
  background: #332E16E5;
}

.slm-email-form-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  background-color: gray;
}

/* Mode centré quand il n'y a pas de résultats */
.slm-email-form-center {
  flex-direction: column;
  /* align-items: center; */
}

/* Mode split en 2/3 - 1/3 quand il y a des résultats */
.slm-email-form-split {
  flex-direction: row;
}

/* Formulaire - 2/3 de l'écran si résultats affichés */
.slm-email-form-container {
  flex: 2;
  background: #ffffff;
  padding: 5%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* max-width: 700px; */
}

/* Contenu du formulaire qui peut défiler */
.slm-email-form-content {
  flex: 1;
  overflow-y: auto;
  max-height: 400px; /* Ajuste la hauteur max du formulaire */
  padding-bottom: 60px; /* Espace pour le bouton */
}

/* Bouton fixe en bas */
.slm-email-form-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  /* background: white; */
  padding: 12px;
  /* box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1); */
  text-align: center;
}

/* Bouton */
.slm-email-form-button {
  background: #332E16E5;
  color: #fce77b;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  transition: background 0.3s;
}

.slm-email-form-button:hover {
  background: #332E16E5;
}

.slm-input-text {
  color: #ffffffe0;
}

#redflag-text {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: rgb(238, 20, 20);
  text-shadow: 2px 2px black;
}